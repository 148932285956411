.carousel {
    .slide {
        background: #fff; }
    .carousel-status {
        font-size: 25px;
        top: 0px;
        right: 78%;
        padding: 0px;
        background-color: #ffffff;
        color: darkGrey;
        text-shadow: 0px 0px 0px; } }

.arrow-button {
    position: absolute;
    height: 20%;
    top: 40%;
    font-size: 3.0rem;
    background-color: transparent;
    color: darkGrey;
    border-style: none;
    transition-duration: 0.4s;
    border-color: transparent;
    border: 0px;
    cursor: pointer;
    box-shadow: 0 0 0 0 !important;
    &:hover {
        border: 0px;
        background-color: transparent;
        border-style: none;
        color: lightGrey;
        border-color: transparent;
        box-shadow: 0 0 0 0; }

    &:focus {
        border: 0px;
        border-style: none;
        background-color: transparent;
        border-width: 0px;
        box-shadow: 0 0 0 0; }

    &:active {
        border: 0px;
        border-style: none;
        background-color: transparent !important;
        color: darkGrey !important;
        border-width: 0px; } }

.tab-pane {
    border-radius: 0px 0px 5px 5px; }

.tab-border {
     border: 1px solid darkgrey;
     border-radius: 5px; }

.filter {
    max-height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: calc(100vh - 100px); }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; }

    &::-webkit-scrollbar-thumb {
        background: #888;
        &:hover {
            background: #555; } } }
