.input-group {
    padding-top: 10px; }

.input-group-text {
    font-size: 14px;
    min-width: 180px; }

.form-control {
    font-size: 14px;
    &:disabled {
        background-color: #fff;
        color: black; } }
